import React from "react";
import { Helmet } from "react-helmet";
import Layout from "./../components/Layout";

const SubPage = () => {
  const pageTitle = "サブページ";

  return (
    <Layout>
      {/* head */}
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={`${pageTitle} - Mock04`}
        meta={[
          { name: "description", content: "このページはモックサイトです。" },
        ]}
      />
      <section>
        <div className="container">
          <h1>こちらは配下ページです</h1>
        </div>
      </section>
    </Layout>
  );
};

export default SubPage;
